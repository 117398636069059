import React from "react";
import { AiFillInstagram, AiFillTwitterCircle, AiOutlineFacebook } from "react-icons/ai";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import "./footer.css";
import Icon from "./Icon";

function Footer({ reference }) {
  return (
  <div className="footer gap-x-12 items-center w-full" ref={reference}>
    <footer className="flex gap-x-8 sm:flex-col sm:ml-6" >
      <div className="column sm:mb-2">
        <div className="font-[40px] flex flex-row  text-[30px] gap-x-2 mt-4 text-white items-center ">
          <AiFillInstagram />
          <p className="p capitalize text-[20px]">ashoktekamofficial</p>
        </div>
        <div className="font-[40px] flex flex-row  text-[30px] gap-x-2 mt-4 text-white items-center ">
          <Icon icon='tritter' width={'25px'} fill={'#fff'} />
          <p className="p capitalize text-[20px]">@Ashoktekamseoni</p>
        </div>
        <div className="font-[40px] flex flex-row  text-[30px] gap-x-2 mt-4 text-white items-center ">
          <AiOutlineFacebook />
          <p className="p capitalize text-[20px]">Ashok Tekam</p>
        </div>
      </div>
      <div className="column">
        <div className="font-[40px] flex flex-row  text-[30px] gap-x-2 mt-4 text-white items-center ">
          <MdEmail />
          <p className="p capitalize text-[20px]">ashoktekam1009@gmail.com</p>
        </div>
        <div className="font-[40px] flex flex-row  text-[30px] gap-x-2 mt-4 text-white items-center ">
          <FaWhatsapp />
          <p className="p capitalize text-[20px]">+91 94251 63765</p>
        </div>
        <div className="font-[40px] flex flex-row  text-[30px] gap-x-2 mt-4 text-white items-center ">
          <MdLocationOn />
          <p className="p capitalize text-[20px] text-left">
            Doctors quarter, Girls college road, opp cooperative bank, Bhagat
            Singh ward seoni.
          </p>
        </div>
      </div>
    </footer>
    <div className="h-10 self-baseline mt-6 sm:ml-6">
    <a className="text-white bg-orange-400 px-4 py-2 rounded" href="#">Join us</a>
    </div>
  </div>
  );
}

export default Footer;
