import React, { useRef } from "react"
import Navbar from "./compoent/Navbar"
import Home from "./compoent/Home"
import About from "./compoent/About"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Footer from "./compoent/Footer"
import Gallery from "./compoent/Gallery"

function App() {

  const contactRef = useRef(null);

  const handleScrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Router>
        <Navbar onClick={handleScrollToContact} />
        <Switch>
          <Route path='/' compoent={Home} exact>
            <Home />
          </Route>
          <Route path='/about' compoent={About} exact>
            <About />
          </Route>
          <Route path='/gallery' compoent={Gallery} exact>
            <Gallery />
          </Route>
        </Switch>
      </Router>
      <Footer reference={contactRef} />
    </div>
  )
}

export default App
