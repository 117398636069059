import React from "react";
import "./gallery.css";
import { eventImg } from './constant';
import { Heading } from "./Helper";

const Gallery = () => {


  return (
    <ol type="1" className="mx-10 sm:mx-2">
      <Heading text={'Photo Gallery'} textColor='text-black' />
      {eventImg.map((item, index) => (
        <li key={index} className="my-8 mx-8 w-full sm:w-auto sm:mx-2 sm:my-4">
          <h2 className="text-xl mt-4 mb-8 font-bold" ><span className="mr-2">{index + 1}:</span>{item.heading}</h2>
          <div className="flex h-1/5 overflow-x-auto sm:gap-x-2 sm:rounded">
            {item.subEvent.map(img => (
              <div className="px-4 pb-10 sm:px-0 sm:pb-4">
                <img src={img} className="rounded h-[270px] min-w-[405px] max-w-full shadow-lg" alt="position" />
              </div>
            ))}
          </div>
          <p className=" mt-4 px-8 sm:px-2 sm:text-sm">{item.details}</p>
        </li>
      ))}
    </ol>
  );
};

export default Gallery;
