import React from "react";
import "./about.css";
import { BsFillPenFill } from "react-icons/bs";

const about = ['जब सेवा भावना आपकी अंतरात्मा में बसी हो तब वह किसी भी स्तर से आरंभ की जाए अपने गंतव्य तक पहुंचती ही है।',

'सदैव समाज धर्म और देश की सेवा भावना से प्रेरित रहते हुए अशोक तेकाम जी ने समाज सेवा में अपने जीवन की शुरुआत जिला सहकारी बैंक के अध्यक्ष पद से की,',

'यहां रहकर सरकार की योजनाओं व आर्थिक नीतियों से ग्रामीण जनता को लाभ पहुंचाते हुए उनकी समस्याओं को दूर करने में निरंतर प्रयासरत रहे।',

'हम जानते हैं कि सर्वसाधारण की सेवा और उनके जीवन में सुधार के लिए हमें अनेक अधिकारों व शक्तियों की आवश्यकता होती है,',

'इसी उद्देश्य से स्वयं लोकतांत्रिक विधि से जनता द्वारा चयनित होकर उन्होंने जनपद अध्यक्ष के पद पर रहते हुए अपनी सेवाओं से क्षेत्र की जनता को लाभ पहुंचाया।',

'समाज में लोगों के प्रति इनकी सेवा भावना और समर्पण को देखते हुए भारतीय जनता पार्टी ने  इन्हें अनेक सामाजिक उत्थान और कल्याण के पदों पर नियुक्त किया जहां रहकर इन्होंने अपनी सेवा व प्रयास समाज की उन्नति में लगाया।',

'ये ऐसे अनेक पदों पर रहकर निरंतर देश में समाज की कल्याणकारी कार्यों में संलग्न रहे हैं।',

'भारतीय जनता पार्टी व राष्ट्रीय स्वयंसेवक संघ द्वारा इन्हें पूर्व में अनेक जनजाति एकता व कल्याण के दायित्व वाले पद भी सौंपे गए हैं और उन पर रहकर इन्होंने अपनी पूर्ण लगन से प्रभावशाली सेवाएं दी हैं।',

' लगातार लोगों को जोड़ने और उनके सहयोग से कार्य करते रहने की योग्यता के कारण राष्ट्रीय स्वयंसेवक संघ द्वारा इन्हें सहकार भारती के एक महत्वपूर्ण कार्यकर्ता के रूप में चयनित किया गया जिसका उद्देश्य ही लोगों के परस्पर सहयोग द्वारा आर्थिक व सामाजिक उन्नति है।',

'अपनी लगनशीलता, समर्पण और परिश्रम से कार्य करते हुए वर्तमान में यह सरकार भर्ती के राष्ट्रीय मंत्री के रूप में ये देश व समाज के हितों में लगे हुए हैं।']

const About = () => {
  return (
    <div className="bg-[#ffffff]">
      <section className="hero" id="about">
        <div className="pt-8 max-w-[350px] h-auto">
          <h2 className="text-3xl font-bold font-serif border-double w-auto text-[#C21E56]">About Ashok tekam</h2>
        </div>
        <div className="about flex flex-auto gap-4 items-center">
          <img src="/Ashok-dp.jpg" id="dp" alt="dp" className="rounded-lg" />

          <span className="text-gray-700 text-left text-base">
          {about.map((val, key )=> (
            <p key={key}>{val}</p>
          ))}
            <span className="justify-end gap-x-2 flex mt-4 text-sm">
              <BsFillPenFill className="text-[#777]" /> अशोक टेकाम
            </span>
          </span>
        </div>
      </section>
    </div>
  );
};
export default About;
