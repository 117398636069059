import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';
import Event from "./Event";
import Contact from "./Contact";
import {images} from './constant';
import Journey from './Journey';


const Intro = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: <button className="slick-prev" aria-label="Previous" />,
    nextArrow: <button className="slick-next" aria-label="Next" />,
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as per your requirements
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
      
        },
      },
    ],
  };
 
  return (
    <Slider {...settings}>
    {images.map((image) => (
      <div key={image.src}>
        <img src={image.src} alt={image.alt} id='IMG' className='' />
      </div>
    ))}
  </Slider>
  );
};

const Home = () => {
  return (<div className='h-auto'>
  <Intro/>
  <Journey/>
  <Event/>  
  <Contact/>
  </div>);
};

export default Home;
