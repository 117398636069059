import React from "react";
import "./about.css";
import { JourneyData } from "./constant";
import { Heading } from "./Helper";

const Journey = () => {
  return (
    <div className="bg-[#252d52] h-auto pb-10">
     <section className="event flex-col flex-auto mb-8">
     <Heading text={'Journey'} textColor='text-white' />
        <div className="about flex flex-auto gap-4 h-full mt-8">
          {JourneyData.map((item, value) => (<>
            <div className={`flex max-w-[520px] sm:max-w-[380px] px-10 sm:px-4 py-2 flex-row ${JourneyData.length -1 !== value && 'border-white border-r sm:border-b'}`}>
              <div className="flex flex-col">
                <div className="">
                  <img src={item.src} className="rounded border-white border-2 p-1 bg-white max-w-350px max-h-350px " alt={item.alt} />
                </div>
                <p className="heading text-[#b6bfe6] font-semibold text-3 mt-6 mb-2">{item.heading}</p>
                <p className="details text-white text-6 font-semibold mb-2">{item.date}</p>
                <p className="rightBorder text-[#8990af] max-h-[700px] overflow-auto">{item.content}</p>
              </div>
            </div>
            </>))}
        </div>
      </section>
    </div>
  );
};
export default Journey;
